import React, { useEffect,useState, useRef  } from 'react';
import { useLocation } from 'react-router-dom';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial, Preload} from '@react-three/drei';
import * as random from 'maath/random/dist/maath-random.esm';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'; 
import UI from "./UI";
import Footer from "./Footer";
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger); 

function Stars(props) {
    const ref = useRef()
    const [sphere] = useState(() => random.inSphere(new Float32Array(2500), { radius: 1.5 }))
    useFrame((state, delta) => {
      ref.current.rotation.x -= delta / 10
      ref.current.rotation.y -= delta / 15
    })
    return (
      <group rotation={[0, 0, Math.PI / 4]}>
        <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
          <PointMaterial transparent color="#666" size={0.0015} sizeAttenuation={true} depthWrite={false} />
        </Points>
      </group>
    )
  }
function Rig() {
    useFrame((state) => {
        state.camera.position.lerp({ x: 0, y: -state.pointer.y / 4, z: state.pointer.x / 2 }, 0.1)
        state.camera.lookAt(-1, 0, 0)
    })
  }

export function Company() {
    
    // 페이지 이동 시 스크롤을 상단으로 이동시킴
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, [location.pathname]);

    useEffect(() => {
        //첫 시작시 헤드타이틀
        gsap.from('.sub_title_head', {
            opacity: 0, 
            y: 10, 
            duration: 0.4, 
            delay: 0.3,
            ease: 'power1.easeInOut', 
        });
        // ScrollTrigger 설정
        gsap.utils.toArray('.contents').forEach((content) => {
          gsap.from(content, {
            opacity: 0,
            y: 50,
            duration: 1,
            scrollTrigger: {
              trigger: content,
              start: 'top 80%', 
              end: 'bottom 0%', 
              toggleActions: 'play none none reverse'
            },
          });
        });
      }, []);    
    
    return (
        <>  
        <UI />
        <Canvas className='bg_sub_space' camera={{ position: [0, 0, 1] }}>
            <Stars />
            <Rig from={-Math.PI / 2} to={Math.PI / 2.66} />
            <Preload all />
        </Canvas>
        <div className="sub_title_head">
            <h1>About us</h1>
            <p className='desc_head'>우리는 가능성을 연구합니다<span className='point_dot'>.</span></p>
        </div>
        <div className="sub_bg">
            <div className="sub_container">
                <div className="wrap_grid start">
                    
                    {/*about 시작*/}
                    <div className="contents about">
                        <h3 className='sub_title'>Hello There 👋<br></br>WE ARE</h3>
                        <p className='title'>우리는 크리에이티브 팀 입니다<span className='point_dot'>.</span></p>
                        <p className='desc'>idLabs는 크리에이티브 팀입니다.<br></br>
                        새로운 세대에게 새로운 디지털 경험을 만들어 줄 것입니다.
                        맞춤형 전략을 통해 미래의 성장 동력이 될 궁극적인 잠재력을 제공합니다.<br></br>
                        크리에이티브 디렉션, 아트 디렉션, 영상 제작, 홍보, 디자인 및 개발팀이 최고의 성과를 낼 수 있도록 돕는 것이 사명입니다.
                        </p>
                        <div className="btn_main_sc">
                            <Link to="/service">  
                                <div className="button">
                                <p className="button__text company">Service view</p> 
                                <div className="button__circle">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="52.725" height="25.992" viewBox="0 0 52.725 25.992">
                                    <path d="M21.432-22.686H-26.22v2.052H21.432a61.087,61.087,0,0,0-11,10.773l1.71,1.2a57.306,57.306,0,0,1,14.364-13,57.306,57.306,0,0,1-14.364-13l-1.71,1.2A61.087,61.087,0,0,0,21.432-22.686Z" transform="translate(26.22 34.656)" fill="#fff" className="svg-arrow"></path>
                                    </svg>
                                </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/*about 끝*/}

                    <div className="contents type2">
                        <div className="wrap_grid">
                            <div className='title'>Who we are</div>
                            <div className='desc'>
                                <div className="desc_word">
                                <h3>디지털 크리에이터<span className='point_dot'>.</span></h3><br></br>
                                <p className="plain">우리는 스스로를 크리에이터라고 자칭합니다.<br></br>
사람의 스타일링과 마찬가지로 클라이언트의 니즈를 분석하고 독자성·캐릭터·시장에서의 포지션 등 깊이 이해하는 것부터 시작합니다.<br></br>
고객의 비즈니스 모델을 찾아내, 「전략, 크리에이티브, 광고 프로모션」모두를 담당해 원하는 결과에 도달할 수 있도록 서포트 합니다.</p>
                                    <div className="btn_main_sc">
                                        <Link to="/team">  
                                            <div className="button">
                                            <p className="button__text company">Team view</p> 
                                            <div className="button__circle">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="52.725" height="25.992" viewBox="0 0 52.725 25.992">
                                                <path d="M21.432-22.686H-26.22v2.052H21.432a61.087,61.087,0,0,0-11,10.773l1.71,1.2a57.306,57.306,0,0,1,14.364-13,57.306,57.306,0,0,1-14.364-13l-1.71,1.2A61.087,61.087,0,0,0,21.432-22.686Z" transform="translate(26.22 34.656)" fill="#fff" className="svg-arrow"></path>
                                                </svg>
                                            </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="contents type2">
                        <div className="wrap_grid">
                            <div className='title'>Slogan</div>
                            <div className='desc'>
                                <div className="desc_word">
                                <h3>Technology for a better society<span className='point_dot'>.</span></h3><br></br>
                                <p className="plain">idlabs는 기술이나 기술 혁신을 활용하여 사회적인 측면에서 개선을 추구하는 노력을 합니다. 우리의 R&D 능력은 사회 문제의 해결과 사회 발전에 긍정적인 역할을 할 수 있다고 믿습니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="contents type2">
                        <div className="wrap_grid">
                            <div className='title'>Loop Approach</div>
                            <div className='desc'>
                            정답이 없고 불확실성이 높은 IT업계에서는 선형 프로세스로는 차별화된 아이디어 실현이 어렵습니다. idLabs의 서비스는 루프 형태로 돌아가는 아이디어 가치 검증 과정을 거칩니다.<br></br>
우리는 다양한 백그라운드를 가진 멤버들이 애자일하게 고속으로 돌아가는 협업을 통해 각 기능 구현부터 UI/UX 개선에 이르기까지  탁월하고 가치있는 IT 경험을 만들어 냅니다.
                                <div className="btn_main_sc">
                                <Link to="/works">  
                                    <div className="button">
                                    <p className="button__text company">Works view</p> 
                                    <div className="button__circle">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="52.725" height="25.992" viewBox="0 0 52.725 25.992">
                                        <path d="M21.432-22.686H-26.22v2.052H21.432a61.087,61.087,0,0,0-11,10.773l1.71,1.2a57.306,57.306,0,0,1,14.364-13,57.306,57.306,0,0,1-14.364-13l-1.71,1.2A61.087,61.087,0,0,0,21.432-22.686Z" transform="translate(26.22 34.656)" fill="#fff" className="svg-arrow"></path>
                                        </svg>
                                    </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                        </div>
                        <div class="concept_img"><img src="img/path_appro.svg" alt="others"></img></div>
                    </div>


                    <div className="contents type2 company_info">
                        <div className="wrap_grid">
                            <div className='title'>Company</div>
                            <div className='desc_cross'>
                                <p className='cp_tit'>NAME</p>
                                <p className='cp_text'>주식회사 아이디랩스 (idLabs. Co Ltd.,)</p>
                                
                                <p className='cp_tit'>OFFICER</p>
                                <p className='cp_text'>인군일</p>
                                
                                <p className='cp_tit'>FOUNDED</p>
                                <p className='cp_text'>2017년 1월 13일</p>
                                
                                <p className='cp_tit'>HEAD OFFICE</p>
                                <p className='cp_text'>13105 경기도 성남시 수정구 고등로 3, A231-232</p>
                                
                                <p className='cp_tit'>SERVICE</p>
                                <p className='cp_text'>
                                브랜드 전략 컨설팅<br></br>
                                광고 캠페인 및 전략적 PR 기획 및 수행<br></br>
                                UI/UX디자인 기획 및 제작<br></br>
                                영상기획 및 제작<br></br>
                                </p>

                                <p className='cp_tit'>MAIL</p>
                                <p className='cp_text'>in@idlabs.co.kr</p>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}
export default Company;