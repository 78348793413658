import * as THREE from "three";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, useAnimations, PerspectiveCamera, Environment, Points, PointMaterial } from '@react-three/drei';
import Overlay from "./Overlay";
import UI from "./UI";
import * as random from 'maath/random/dist/maath-random.esm';

function Stars(props) {
  const ref = useRef()
  const [sphere] = useState(() => random.inSphere(new Float32Array(10000), { radius: 100 }))
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 10
    ref.current.rotation.y -= delta / 15
  })
  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
        <PointMaterial transparent color="#ffa0e0" size={0.15} sizeAttenuation={true} depthWrite={false} />
      </Points>
    </group>
  )
}

function Space ({ scroll, ...props}){
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./idlabs_space29.glb')
  const { actions } = useAnimations(animations, group)
  
  //블랜더 카메라 프레임 설정
  useEffect(() => void (actions["CameraAction.005"].play().paused = true), [actions])
  useFrame((state) => {
    actions["CameraAction.005"].time = THREE.MathUtils.lerp(actions["CameraAction.005"].time, actions["CameraAction.005"].getClip().duration * scroll.current, 0.05)
    group.current.children[0].children.forEach((child, index) => {
      //오브젝트 떠다니는 애니메이션
      const et = state.clock.elapsedTime
      child.position.y = Math.sin((et + index * 2000) / 2) * 1
      child.rotation.x = Math.sin((et + index * 2000) / 3) / 10
      child.rotation.y = Math.cos((et + index * 2000) / 2) / 10
      child.rotation.z = Math.sin((et + index * 2000) / 3) / 10    
    })
  })

  return (
    <>
     <group ref={group} {...props} dispose={null}>
        <group name="OverallParent" position={[0.061, 4.042, 0.346]} scale={0.25}>
          
        <group name="FireBall">
            <mesh name="Mesh_Fireball" geometry={nodes.Mesh_Fireball.geometry} material={materials['M_Fireball.001']} />
            <mesh name="Mesh_Fireball_1" geometry={nodes.Mesh_Fireball_1.geometry} material={materials['M_Fireball.002']} />
            <mesh name="Mesh_Fireball_2" geometry={nodes.Mesh_Fireball_2.geometry} material={materials['M_Fireball.003']} />
          </group>
          <group name="Planet001">
            <mesh name="Mesh_Planet001" geometry={nodes.Mesh_Planet001.geometry} material={materials['M_Planet.001_1']} />
            <mesh name="Mesh_Planet001_1" geometry={nodes.Mesh_Planet001_1.geometry} material={materials['M_Planet.001_2']} />
            <mesh name="Mesh_Planet001_2" geometry={nodes.Mesh_Planet001_2.geometry} material={materials['M_Planet.001_3']} />
            <mesh name="Mesh_Planet001_3" geometry={nodes.Mesh_Planet001_3.geometry} material={materials['M_Planet.001_4']} />
            <mesh name="Mesh_Planet001_4" geometry={nodes.Mesh_Planet001_4.geometry} material={materials['M_Planet.001_5']} />
            <mesh name="Mesh_Planet001_5" geometry={nodes.Mesh_Planet001_5.geometry} material={materials['M_Planet.001_6']} />
          </group>
          <group name="Planet002">
            <mesh name="Mesh_Planet002" geometry={nodes.Mesh_Planet002.geometry} material={materials['M_Planet.002_1']} />
            <mesh name="Mesh_Planet002_1" geometry={nodes.Mesh_Planet002_1.geometry} material={materials['M_Planet.002_2']} />
            <mesh name="Mesh_Planet002_2" geometry={nodes.Mesh_Planet002_2.geometry} material={materials['M_Planet.002_3']} />
          </group>
          <group name="Ship">
            <mesh name="Mesh_Ship" geometry={nodes.Mesh_Ship.geometry} material={materials['M_Ship.001']} />
            <mesh name="Mesh_Ship_1" geometry={nodes.Mesh_Ship_1.geometry} material={materials['M_Ship.002']} />
            <mesh name="Mesh_Ship_2" geometry={nodes.Mesh_Ship_2.geometry} material={materials['M_Ship.003']} />
            <mesh name="Mesh_Ship_3" geometry={nodes.Mesh_Ship_3.geometry} material={materials['M_Ship.004']} />
            <mesh name="Mesh_Ship_4" geometry={nodes.Mesh_Ship_4.geometry} material={materials['M_Ship.005']} />
            <mesh name="Mesh_Ship_5" geometry={nodes.Mesh_Ship_5.geometry} material={materials['M_Ship.006']} />
            <mesh name="Mesh_Ship_6" geometry={nodes.Mesh_Ship_6.geometry} material={materials['M_Ship.007']} />
            <mesh name="Mesh_Ship_7" geometry={nodes.Mesh_Ship_7.geometry} material={materials['M_Ship.008']} />
            <mesh name="Mesh_Ship_8" geometry={nodes.Mesh_Ship_8.geometry} material={materials['M_Ship.009']} />
          </group>
          <group name="Stone">
            <mesh name="Mesh_Stone" geometry={nodes.Mesh_Stone.geometry} material={materials['M_Stone.001']} />
            <mesh name="Mesh_Stone_1" geometry={nodes.Mesh_Stone_1.geometry} material={materials['M_Stone.002']} />
          </group>
          <group name="Tele">
            <mesh name="Mesh_Tele" geometry={nodes.Mesh_Tele.geometry} material={materials['M_Tele.001']} />
            <mesh name="Mesh_Tele_1" geometry={nodes.Mesh_Tele_1.geometry} material={materials['M_Tele.002']} />
            <mesh name="Mesh_Tele_2" geometry={nodes.Mesh_Tele_2.geometry} material={materials['M_Tele.003']} />
            <mesh name="Mesh_Tele_3" geometry={nodes.Mesh_Tele_3.geometry} material={materials['M_Tele.004']} />
            <mesh name="Mesh_Tele_4" geometry={nodes.Mesh_Tele_4.geometry} material={materials['M_Tele.005']} />
            <mesh name="Mesh_Tele_5" geometry={nodes.Mesh_Tele_5.geometry} material={materials['M_Tele.006']} />
          </group>
          <group name="UFO">
            <mesh name="Mesh_UFO" geometry={nodes.Mesh_UFO.geometry} material={materials['M_UFO.001']} />
            <mesh name="Mesh_UFO_1" geometry={nodes.Mesh_UFO_1.geometry} material={materials['M_UFO.002']} />
            <mesh name="Mesh_UFO_2" geometry={nodes.Mesh_UFO_2.geometry} material={materials['M_UFO.003']} />
            <mesh name="Mesh_UFO_3" geometry={nodes.Mesh_UFO_3.geometry} material={materials['M_UFO.004']} />
          </group>

        </group>
        
        <group name="Camera" position={[-1.78, 2.04, 23.58]} rotation={[1.62, 0.01, 0.11]}>
        <PerspectiveCamera makeDefault far={100} near={0.1} fov={28} rotation={[-Math.PI / 2, 0, 0]}>
          <directionalLight
            castShadow
            position={[10, 20, 15]}
            shadow-camera-right={8}
            shadow-camera-top={8}
            shadow-camera-left={-8}
            shadow-camera-bottom={-8}
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
            intensity={2}
            shadow-bias={-0.0001}
          />
        </PerspectiveCamera>
      </group>
    </group>
    </>
  );
}

export default function App() {
  const overlay = useRef()
  const caption = useRef()
  const scroll = useRef(0)
  return (
    <>
    <Canvas shadows eventSource={document.getElementById("root")} eventPrefix="client">
      <Stars />
      <ambientLight intensity={0.5} />
      <hemisphereLight args={["#ffffff","#121212",6]} />
      <Suspense fallback={null}>
      <Space scroll={scroll} />
      <Environment preset="city" />
      </Suspense>
    </Canvas>
    <UI />
    <Overlay ref={overlay} caption={caption} scroll={scroll} />
    </>
  )
}

useGLTF.preload('./idlabs_space29.glb');