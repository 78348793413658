import React, { useEffect,useState, useRef  } from 'react';
import { useLocation } from 'react-router-dom';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial } from '@react-three/drei';
import * as random from 'maath/random/dist/maath-random.esm';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'; 
import UI from "./UI";
import Footer from "./Footer";

gsap.registerPlugin(ScrollTrigger); 

function Stars(props) {
  const ref = useRef()
  const [sphere] = useState(() => random.inSphere(new Float32Array(5000), { radius: 1.5 }))
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 10
    ref.current.rotation.y -= delta / 15
  })
  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
        <PointMaterial transparent color="#ffa0e0" size={0.002} sizeAttenuation={true} depthWrite={false} />
      </Points>
    </group>
  )
}

export function Rnd() {

    // 페이지 이동 시 스크롤을 상단으로 이동시킴
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, [location.pathname]);

    useEffect(() => {
        //첫 시작시 헤드타이틀
        gsap.from('.sub_title_head', {
            opacity: 0, 
            y: 10, 
            duration: 0.4, 
            delay: 0.3,
            ease: 'power1.easeInOut', 
        });
        // ScrollTrigger 설정
        gsap.utils.toArray('.contents').forEach((content) => {
          gsap.from(content, {
            opacity: 0,
            y: 50,
            duration: 1,
            scrollTrigger: {
              trigger: content,
              start: 'top 80%', 
              end: 'bottom 0%', 
              toggleActions: 'play none none reverse'
            },
          });
        });
      }, []);    
    
    return (
        <>  
        <UI />
        <Canvas camera={{ position: [0, 0, 1] }}>
            <Stars />
        </Canvas>
        <div className="sub_title_head">
            <h1>Research & Development</h1>
            <p className='desc_head'>팀 페이지 입니다.</p>
        </div>
        <div className="sub_bg">
            <div className="sub_container">
                <div className="wrap_grid start">
                    
                    {/*about 시작*/}
                    <div className="contents about">
                        <h3 className='sub_title'>Hello There 👋<br></br>WE ARE</h3>
                        <p className='title'>우리는 크리에이티브 팀 입니다.</p>
                        <p className='desc'>아이디랩스는 크리에이티브 팀입니다.<br></br>
    밀레니얼 세대와 Z세대에게 새로운 브랜드 경험을 만들어 줄 것입니다.<br></br>
    우리는 브랜드 스타일러스(Brand Stylers)로서 각 브랜드만의 '스타일'을 만들어냅니다.<br></br>
    맞춤형 전략을 통해 궁극적인 잠재력을 제공합니다.<br></br>
    크리에이티브 디렉션, 아트 디렉션, 홍보, 디자인. 브랜드가 최고의 성과를 낼 수 있도록 돕는 것이 사명입니다.</p>
                    </div>
                    {/*about 끝*/}

                    <div className="contents type2">
                        <div className="wrap_grid">
                            <div className='title'>Who we are</div>
                            <div className='desc'>
                                <div className="desc_word">
                                <h3>디지털 크리에이터</h3><br></br>
                                <p className="plain">우리는 스스로를 크리에이터라고 자칭합니다.<br></br>
사람의 스타일링과 마찬가지로 클라이언트의 니즈를 분석하고 독자성·캐릭터·시장에서의 포지션 등 깊이 이해하는 것부터 시작합니다.<br></br>
브랜드의 「다움」과, 세상에 「만들고 싶은 이미지」가 상승하는 독자적인 스타일을 찾아내, 「전략, 크리에이티브, 광고 프로모션」모두를 담당해 브랜드의 인상을 스타일링을 해 갑니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                                   
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}
export default Rnd;