import React, { useState, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial, Preload } from '@react-three/drei';
import * as random from 'maath/random/dist/maath-random.esm';

function Stars(props) {
    const ref = useRef()
    const [sphere] = useState(() => random.inSphere(new Float32Array(3000), { radius: 1.5 }))
    useFrame((state, delta) => {
      ref.current.rotation.x -= delta / 10
      ref.current.rotation.y -= delta / 15
    })
    return (
      <group rotation={[0, 0, Math.PI / 4]}>
        <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
          <PointMaterial transparent color="#ffa0e0" size={0.002} sizeAttenuation={true} depthWrite={false} />
        </Points>
      </group>
    )
  }
  function Rig() {
    useFrame((state) => {
        state.camera.position.lerp({ x: 0, y: -state.pointer.y / 4, z: state.pointer.x / 2 }, 0.1)
        state.camera.lookAt(-1, 0, 0)
    })
  }

const Loading = () => {
    return (
        <>
            <Canvas className='bg_sub_space' camera={{ position: [0, 0, 1] }}>
                <Stars />
                <Rig from={-Math.PI / 2} to={Math.PI / 2.66} />
                <Preload all />
            </Canvas>
            <div className="container">
                <h1 className="logo"><img src="img/logo_def.svg" alt="idlabs"></img>
                </h1>
            </div>
            <div className='loading_wrap'>
                <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px">
                    <path d="M 50,50 L 33,60.5 a 20 20 -210 1 1 34,0 z" fill="#ffffff">
                    <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="1.2s" repeatCount="indefinite"/>
                    </path>
                    <circle cx="50" cy="50" r="16" fill="#121212"></circle>
                </svg>
                <div className="loading_txt">
                    <h3>Loading now</h3>
                </div>
            </div>
        </>
    );
};

export default Loading;
