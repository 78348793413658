import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import gsap from 'gsap';

function UI() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      gsap.to('.bg_menu_on', {
        duration: 0.5, 
        opacity: 1, 
        y: 0, 
        ease: 'power2.easeOut', 
      });
    } else {
      gsap.to('.bg_menu_on', {
        duration: 0.5, 
        y: 15, 
        opacity: 0, 
        ease: 'power2.easeIn', 
      });
    }
  }, [isMenuOpen]);

  return (
    <> 
    <div className='container'>
      <h1 className="logo"><NavLink to="/"><img src="img/logo_def.svg" alt="아이디랩스" /></NavLink></h1>
      <div className='top_link_right'>
        <button className="nav-trigger" onClick={toggleMenu}>
          <div className="nav-trigger__circle"></div>
          <div className="nav-trigger__lines">
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </button>
        <div className={`bg_menu_on ${isMenuOpen ? 'open' : ''}`}>
          <button className="nav-trigger-close" onClick={toggleMenu}>
            <div className="nav-trigger__circle"></div>
            <p className='nav_close'><img src='img/icon_close.svg'></img></p>
          </button>
          <div className='gnb_wrap'>
            <div className='menu_wrap'>
              <p className='item'><NavLink to="/service" onClick={toggleMenu}>Services</NavLink></p>
              <p className='item'><NavLink to="/works" onClick={toggleMenu}>Works</NavLink></p>
              <p className='item'><NavLink to="/team" onClick={toggleMenu}>Team</NavLink></p>
              <p className='item'><NavLink to="/company" onClick={toggleMenu}>About&nbsp;us</NavLink></p>
            </div>
            <div className='sub_menu_wrap'>
              <p className='item'><a href='https://blog.idlabs.kr' target='_blank'>Blog</a></p>
              <p className='item'><a href='https://www.instagram.com/idlabs0113' target='_blank'>Instagram</a></p>
              <p className='item'><a href='https://dribbble.com/idlabs' target='_blank'>Dribbble</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default UI;