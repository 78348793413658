import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";


export default function Works_list() {

    const { page } = useParams();
    const [works, setWorks] = useState([]);


    useEffect(() => {
        fetch(`https://idlabs.co.kr/api/get_works.php?page=1`)
            .then(res => {
                return res.json();
            })
            .then(data => {
                setWorks(data);
            });
    }, []);

    return <>
        {works.map(work => (

            < div className='type2_item' >

                <div className='type2_item_poster'>
                    <div className='poster_wrap'><img src={"http://idlabs.co.kr/upload/work/" + work.background_file} className='item_image'></img></div>
                    <div className='overlay_txt'>
                        <div className='txt_wrap'>
                            <div className='wrap_icon'>
                                <p className='icon_lock'><img src='img/icon_lock.svg'></img></p>
                                <p className='icon_lock_word'>정보를 원하시면 고객문의를 통해 신청해 주세요.</p>
                            </div>
                            <p className='cp_text2_desc'>{work.sub_title}</p>
                        </div>
                        <div className='dim_txt'></div>
                    </div>
                </div>
                <p className='cp_text2'>{work.company}<a target='_blank' href={work.url} className='btn_link'><img src='img/icon_link.svg'></img></a></p>
                <p className='cp_tit2'>{work.category}</p>
            </div >
        ))
        }
    </>
}