import React from 'react';

function Footer() {  
  return (
    <>
    <div className='footer'>
        <div className="ending">
            <a href="https://y1uu32z0yvs.typeform.com/to/RzGfDTOv" target='_blank'>
            <h2 className="title_head">
              <span className='char'>W</span>
              <span className='char'>O</span>
              <span className='char'>R</span>
              <span className='char'>K&nbsp;</span>
              <span className='char'>W</span>
              <span className='char'>I</span>
              <span className='char'>T</span>
              <span className='char'>H&nbsp;</span>
              <span className='char'>U</span>
              <span className='char'>S</span>
              <span className='char point_dot'>.</span>
            </h2>
            <p className="desc1">비즈니스의 홍보 및 세일즈 전략, 영상제작, 웹사이트 제작 등에 관한 상담은 언제든지 문의해 주세요.🙇‍♂️</p>
            </a>

            <div className='footer_botttom_wrap'>
              <div className='fb_container1'>
                <p className='text'><a href="mailto:in@idlabs.co.kr">IN@IDLABS.CO.KR</a></p>
                <p className='text'>13105, 경기 성남시 수정구 고등로 3, A231-232</p>
              </div>
              <div className='fb_container2'>
                <p className='text'>© idLabs, inc. 2024 All rights reserved.</p>
              </div>
              <div className='fb_container3'>
                <p className='text'><a href="https://blog.idlabs.kr" target='_blank'>BLOG</a></p>
                <p className='text'><a href="https://www.instagram.com/idlabs0113" target='_blank'>INSTGRAM</a></p>
                <p className='text'><a href="https://dribbble.com/idlabs" target='_blank'>dribbble</a></p>
              </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default Footer;