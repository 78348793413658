import React, { useEffect, forwardRef } from "react";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const Overlay = forwardRef(({ caption, scroll }, ref) => {  
  //첫 시작시 로고 애니메이션
  useEffect(() => {
    gsap.from(".scene1_1", {
        opacity: 0,
        y: 50,
        duration: 2,
        scrollTrigger: {
            trigger: ".scene1_1",
            start: "top 80%",
            end: "top 20%",
            toggleActions: "restart none none reverse",
        }
    });
  }, []);

//스크롤 이벤트 핸들러 GSAP
const handleScroll = (e) => {

  scroll.current = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight);
  const scrollValue = parseFloat(scroll.current.toFixed(2)); //소숫점 두자리
  const sceneTitleElement = document.querySelector('.scene_title');

  //밑 부분 숫자 애니메이션 scrollValue에 따른 stage체크
  let newText = '01'; 
  if (scrollValue >= 0 && scrollValue < 0.10) {
    newText = '01';
  } else if (scrollValue >= 0.10 && scrollValue < 0.23) {
    newText = '02';    
  } else if (scrollValue >= 0.23 && scrollValue < 0.33) {
    newText = '03';
  } else if (scrollValue >= 0.33 && scrollValue < 0.51) {
    newText = '04';
  } else if (scrollValue >= 0.51 && scrollValue < 0.65) {
    newText = '05';
  } else if (scrollValue >= 0.65 && scrollValue < 0.93) {
    newText = '06';
  } else if (scrollValue >= 0.93 && scrollValue < 1.01) {
    newText = '07';
  } else {
    newText = '';    
  }

  //밑 부분 숫자 애니메이션
  if (sceneTitleElement.innerText !== newText) { 
    sceneTitleElement.innerText = newText; 
    gsap.from(sceneTitleElement, {
        opacity: 0,
        y: -20,  
        duration: 0.6,
        clearProps: 'all' 
    });
  }
  caption.current.innerText = scrollValue;
};
  
  return(
  <div
      ref={ref}
      onScroll={handleScroll}
      className="scroll">

      <div className="info">
        <span className="scene_title">01</span><span className="default">| 07</span>
      </div>

    <div style={{ height: "350vh" }}>
      <div className="scene1">
          <div className="scene1_1">
            <p className="first_text"><img src="img/text_scene1.svg" alt="ID"></img></p>
          </div>
          <div className="scene1_2">
            <h3 className="second_text">              
            <p className="title_head">Labs. DNA</p>
            우리의 차별성은 독창성 입니다. idLabs는 개인의 독창적인 DNA를 원동력 삼아 긴밀한 소통을 통해 창의적인 원 팀으로 움직입니다. 
            이러한 강점으로 다수의 공공기관 및 일반기업과 협업하여 뛰어난 마케팅 및 플랫폼 개발 실적을 갖고 있습니다. <br></br>
            '사람과 지식의 탐구'라는 신념 아래, 새로운 아이디어와 깊이 있는 통찰력으로 이로운 IT 생태계를 구축합니다.
            </h3>
          </div>
      </div>
    </div>

    <div className="scene3" style={{ height: "200vh" }}>
      <div className="dot">
        <h2 className="title_head main">Services<span className="last_dot">.</span></h2>
        <p className="desc">WE DO STRATEGY, COMMUNICATION & DESIGN, idLabs는 디지털 소셜마케팅을 기반으로 하는 디지털 크리에이티브 전문 기획사 입니다.</p>
        <div className="btn_main_sc">
          <Link to="/service">
          <div className="button">
            <p className="button__text">More View</p> 
            <div className="button__circle">
              <svg xmlns="http://www.w3.org/2000/svg" width="52.725" height="25.992" viewBox="0 0 52.725 25.992">
              <path d="M21.432-22.686H-26.22v2.052H21.432a61.087,61.087,0,0,0-11,10.773l1.71,1.2a57.306,57.306,0,0,1,14.364-13,57.306,57.306,0,0,1-14.364-13l-1.71,1.2A61.087,61.087,0,0,0,21.432-22.686Z" transform="translate(26.22 34.656)" fill="#fff" className="svg-arrow"></path>
              </svg>
            </div>
          </div>
          </Link>
        </div>
      </div>   
    </div>

    <div className="scene3" style={{ height: "200vh" }}>
      <div className="dot">
        <h2 className="title_head main">Works<span className="last_dot">.</span></h2>
        <p className="desc">300프로젝트 이상을 소화하며 쌓아올린 경험을 바탕으로 새로운 IT생태계를 만들어 갑니다.</p>

        <div className="btn_main_sc">
          <Link to="/works">  
            <div className="button">
              <p className="button__text">More View</p> 
              <div className="button__circle">
                <svg xmlns="http://www.w3.org/2000/svg" width="52.725" height="25.992" viewBox="0 0 52.725 25.992">
                  <path d="M21.432-22.686H-26.22v2.052H21.432a61.087,61.087,0,0,0-11,10.773l1.71,1.2a57.306,57.306,0,0,1,14.364-13,57.306,57.306,0,0,1-14.364-13l-1.71,1.2A61.087,61.087,0,0,0,21.432-22.686Z" transform="translate(26.22 34.656)" fill="#fff" className="svg-arrow"></path>
                </svg>
              </div>
            </div>
          </Link>
        </div>
      </div> 
    </div>

    <div className="scene4" style={{ height: "200vh" }}>
      <div className="dot">
        <h2 className="title_head main">Team.</h2>
        <p className="desc">idLabs는 다양한 백그라운드와 전문성을 가진 멤버들이 창의성을 서로 자극하고 공동창작하는 조직입니다.<br></br>각각이 자신의 영역에서 힘을 발휘하면서 콜라보레이션에 의해 프로젝트를 진행하고 있습니다.</p>

        <div className="btn_main_sc">
            <Link to="/team">  
              <div className="button">
              <p className="button__text">More View</p> 
                <div className="button__circle">
                  <svg xmlns="http://www.w3.org/2000/svg" width="52.725" height="25.992" viewBox="0 0 52.725 25.992">
                    <path d="M21.432-22.686H-26.22v2.052H21.432a61.087,61.087,0,0,0-11,10.773l1.71,1.2a57.306,57.306,0,0,1,14.364-13,57.306,57.306,0,0,1-14.364-13l-1.71,1.2A61.087,61.087,0,0,0,21.432-22.686Z" transform="translate(26.22 34.656)" fill="#fff" className="svg-arrow"></path>
                  </svg>
                </div>
              </div>
            </Link>
        </div>
      </div> 
    </div>

    <div className="scene5" style={{ height: "200vh" }}>
      <div className="dot">
        <h2 className="title_head main">About us<span className="last_dot">.</span></h2>
        <p className="desc">우리는 비즈니스의 가능성에 대해 연구합니다. 디지털 크리에이터 idLabs에 대해 더 알아보아요.</p>

        <div className="btn_main_sc">
          <Link to="/company">  
              <div className="button">
                <p className="button__text">More View</p> 
                <div className="button__circle">
                  <svg xmlns="http://www.w3.org/2000/svg" width="52.725" height="25.992" viewBox="0 0 52.725 25.992">
                    <path d="M21.432-22.686H-26.22v2.052H21.432a61.087,61.087,0,0,0-11,10.773l1.71,1.2a57.306,57.306,0,0,1,14.364-13,57.306,57.306,0,0,1-14.364-13l-1.71,1.2A61.087,61.087,0,0,0,21.432-22.686Z" transform="translate(26.22 34.656)" fill="#fff" className="svg-arrow"></path>
                  </svg>
                </div>
              </div>
            </Link>
        </div>
      </div>
    </div>

    <div className="scene6" style={{ height: "200vh" }}>
      <div className="dot">
        <h2 className="title_head"></h2>
        <p className="desc"></p>
      </div>
    </div>

    <div className="scene7" style={{ height: "200vh" }}>
      <div className="dot">
        <h2 className="title_head main ending">Contact<span className="last_dot">.</span></h2>
        <div className="ending">
            <p className="desc1">idLabs는 인류의 체험과 가능성을 확장하는 Creative Company입니다.<br></br> R&D 활동 및 서비스 개발에 힘쓰는 회사입니다.</p>
              <div className="end_btn_wrap">
                <a href="https://y1uu32z0yvs.typeform.com/to/RzGfDTOv" className="end_link" target="_blank">Request</a>
              </div>
            <p className="address">13105, 경기 성남시 수정구 고등로 3<br></br>218, Sujeong-ro, Sujeong-gu, Seongnam-si, Gyeonggi-do, Republic of Korea</p>
            <p className="copyright">© idLabs, inc. 2024 All rights reserved.</p>
        </div>
      </div>
    </div>

    <span className="caption" ref={caption}>
      0.00
    </span>

    <span className='scroll-btn'>
      <span className="mouse">
        <span></span>
      </span>
      <p className="txt_dec">scroll</p>
    </span>

  </div>
  
  );

  });

export default Overlay;