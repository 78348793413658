import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial, Preload } from '@react-three/drei';
import * as random from 'maath/random/dist/maath-random.esm';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import UI from "./UI";
import Works_list from './component/Works_list';
import Footer from "./Footer";

gsap.registerPlugin(ScrollTrigger);

function Stars(props) {
  const ref = useRef()
  const [sphere] = useState(() => random.inSphere(new Float32Array(2500), { radius: 1.5 }))
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 10
    ref.current.rotation.y -= delta / 15
  })
  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
        <PointMaterial transparent color="#666" size={0.0015} sizeAttenuation={true} depthWrite={false} />
      </Points>
    </group>
  )
}
function Rig() {
  useFrame((state) => {
    state.camera.position.lerp({ x: 0, y: -state.pointer.y / 4, z: state.pointer.x / 2 }, 0.1)
    state.camera.lookAt(-1, 0, 0)
  })
}

export function Company() {

  const [works, setWorks] = useState();

  // 페이지 이동 시 스크롤을 상단으로 이동시킴
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    //첫 시작시 헤드타이틀
    gsap.from('.sub_title_head', {
      opacity: 0,
      y: 10,
      duration: 0.4,
      delay: 0.3,
      ease: 'power1.easeInOut',
    });
    // ScrollTrigger 설정
    gsap.utils.toArray('.contents').forEach((content) => {
      gsap.from(content, {
        opacity: 0,
        y: 50,
        duration: 1,
        scrollTrigger: {
          trigger: content,
          start: 'top 80%',
          end: 'bottom 0%',
          toggleActions: 'play none none reverse'
        },
      });
    });
  }, []);

  return (
    <>
      <UI />
      <Canvas className='bg_sub_space' camera={{ position: [0, 0, 1] }}>
        <Stars />
        <Rig from={-Math.PI / 2} to={Math.PI / 2.66} />
        <Preload all />
      </Canvas>
      <div className="sub_title_head">
        <h1>Works</h1>
        <p className='desc_head'>우리는 가능성을 연구합니다<span className='point_dot'>.</span></p>
      </div>
      <div className="sub_bg">
        <div className="sub_container">
          <div className="wrap_grid start">

            <div className='work_menu_head contents'>
              <p className='work_menu_top'>ALL<span className="alert">+ 354</span></p>
              <p className='work_menu_sub'>WEB</p>
              <p className='work_menu_sub'>eShop</p>
              <p className='work_menu_sub'>VIDEO</p>
              <p className='work_menu_sub'>MARKETING</p>
            </div>

            <div className="contents type_g2 start">
              <Works_list />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Company;