import React, { useEffect,useState, useRef  } from 'react';
import { useLocation } from 'react-router-dom';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial, Preload } from '@react-three/drei';
import * as random from 'maath/random/dist/maath-random.esm';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'; 
import UI from "./UI";
import Footer from "./Footer";

gsap.registerPlugin(ScrollTrigger); 

function Stars(props) {
    const ref = useRef()
    const [sphere] = useState(() => random.inSphere(new Float32Array(2500), { radius: 1.5 }))
    useFrame((state, delta) => {
      ref.current.rotation.x -= delta / 10
      ref.current.rotation.y -= delta / 15
    })
    return (
      <group rotation={[0, 0, Math.PI / 4]}>
        <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
          <PointMaterial transparent color="#666" size={0.0015} sizeAttenuation={true} depthWrite={false} />
        </Points>
      </group>
    )
  }
function Rig() {
    useFrame((state) => {
        state.camera.position.lerp({ x: 0, y: -state.pointer.y / 4, z: state.pointer.x / 2 }, 0.1)
        state.camera.lookAt(-1, 0, 0)
    })
}

export function Service() {
        
    // 페이지 이동 시 스크롤을 상단으로 이동시킴
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, [location.pathname]);

    useEffect(() => {
        //첫 시작시 헤드타이틀
        gsap.from('.sub_title_head', {
            opacity: 0, 
            y: 10, 
            duration: 0.4, 
            delay: 0.3,
            ease: 'power1.easeInOut', 
        });
        // ScrollTrigger 설정
        gsap.utils.toArray('.contents').forEach((content) => {
          gsap.from(content, {
            opacity: 0,
            y: 50,
            duration: 1,
            scrollTrigger: {
              trigger: content,
              start: 'top 80%', 
              end: 'bottom 0%', 
              toggleActions: 'play none none reverse'
            },
          });
        });
      }, []);    
    
    return (
        <>  
        <UI />
        <Canvas className='bg_sub_space' camera={{ position: [0, 0, 1] }}>
            <Stars />
            <Rig from={-Math.PI / 2} to={Math.PI / 2.66} />
            <Preload all />
        </Canvas>
        <div className="sub_title_head">
            <h1>Service</h1>
            <p className='desc_head'>WE DO STRATEGY, COMMUNICATION & DESIGN <br></br>idLabs는 크리에이티브 팀입니다<span className='point_dot'>.</span><br></br>디지털 마케팅 기획 및 실행, 영상 제작, 플랫폼 구축 등 디지털 홍보 원스톱 서비스를 제공합니다.<span className='point_dot'>.</span></p>
        </div>
        <div className="sub_bg">
            <div className="sub_container">
                <div className="wrap_grid start">

                    <div className="contents type2">
                        <div className="wrap_grid start">
                            <div className='title'>WHAT WE DO</div>
                            <div className='desc'>
                                <div className="desc_word">
                                <h3>We are creative partner</h3><br></br>
                                <p className="plain">
                                우리는 스스로를 크리에이터라고 자칭합니다.<br></br>
클라이언트의 목표를 깊이 있게 이해하고, 체험이나 공유를 통한 디자인 제작·커뮤니케이션 설계 그리고 프로젝트를 성공으로 이끄는 전체를 실행합니다.<br></br>
프로젝트의 과제나 예산에 따라 최적의 솔루션을 제공해 빠르고 유연하게 목표에 도달할 수 있도록 서포트합니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="contents type2">
                        <div className="wrap_grid">
                            <div className='title'>                                
                                <div className='poster_type1'>
                                    <div className='wrap_anim'>
                                        <div className="circle circle1"></div>
                                        <div className="circle circle2"></div>
                                        <div className="circle circle3"></div>
                                        <div className="circle circle4"></div>
                                    </div>
                                </div>
                            </div>
                            <div className='desc'>
                                <div className="desc_word">                                    
                                    <div className='slogan_txt'>
                                        <p>Digital & Video Works<span className='point_dot'>.</span></p>
                                        <p className='sub_title'>디지털 & 영상 웍스<span className='point_dot'>.</span></p>
                                    </div>
                                    <p className="plain">
                                    기업 및 관공서 홈페이지, 이커머스 사이트, 브랜드 사이트, 캠페인 사이트, 랜딩 페이지 등의 웹사이트나 인터랙티브 웹 콘텐츠 등. 
다양한 디바이스에 대응한 디지털 체험을 프로듀싱합니다. <br></br>
높은 수준의 디자인과 탄탄한 개발 퍼포먼스를 강점으로 차별화된 고품질 아웃풋을 실현합니다.
                                    </p>
                                    <p className='list_txt'>
                                        <span>WEB</span>
                                        <span>DESIGN</span>
                                        <span>DEVELOPMENT</span>
                                        <span>VIDEO/XR</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="contents type2">
                        <div className="wrap_grid">
                            <div className='title'>                                
                                <div className='poster_type1 '>
                                    <div className='wrap_anim'>
                                        <div className="Bcircle1"></div>
                                        <div className="Bcircle2"></div>
                                        <div className="Bcircle3"></div>
                                        <div className="Bcircle4"></div>
                                    </div>
                                </div>
                            </div>
                            <div className='desc'>
                                <div className="desc_word">                                    
                                    <div className='slogan_txt'>
                                        <span>eShop & Product Management<span className='point_dot'>.</span></span>
                                        <span className='sub_title'>이커머스 & 상품제작<span className='point_dot'>.</span></span>
                                    </div>
                                    <p className="plain">
                                    국내 쇼핑호스트 업체인 cafe24, 고도몰 등을 이용한 이커머스 구축은 물론 Shopify와 같은 해외플랫폼도 대응합니다. <br></br>
뿐만 아니라, 클라이언트의 브랜드에 대한 깊은 탐구를 바탕으로 브랜딩 및 마케팅 컨텐츠 제작, 세일즈 전략 컨설팅 서비스를 제공합니다.
                                    </p>
                                    <p className='list_txt'>
                                        <span>Cafe24 / 고도몰 / Shopify 운영대행</span>
                                        <span>브랜드 및 상품 전략 컨설팅</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="contents type2">
                        <div className="wrap_grid">
                            <div className='title'>                                
                                <div className='poster_type1'>
                                    <div className='wrap_anim'>
                                        <div className="Ccircle1"></div>
                                        <div className="Ccircle2"></div>
                                        <div className="Ccircle3"></div>
                                        <div className="Ccircle4"></div>
                                    </div>
                                </div>
                            </div>
                            <div className='desc'>
                                <div className="desc_word">                                    
                                    <div className='slogan_txt'>
                                        <span>Marketing & Communications<span className='point_dot'>.</span></span>
                                        <span className='sub_title'>마케팅 & 프로모션<span className='point_dot'>.</span></span>
                                    </div>
                                    <p className="plain">
                                    틀에 박힌 고정관념에 구애받지 않고 클라이언트의 과제 해결에 효과적인 시책을 KGI/KPI로 설계하여 미디어 플래닝, 프로모션 아이디어, 실행·검증까지 단번에 서포트합니다.
                                    </p>
                                    <p className='list_txt'>
                                        <span>MARKETING PLANNING</span>
                                        <span>COMMUNICATION</span>
                                        <span>CAMPIGN PLANNING</span>
                                        <span>CONSULTING</span>
                                        <span>SOCIAL MEDIA MENAGEMENT</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                                   
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}
export default Service;