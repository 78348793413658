import React, { useEffect,useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial, Preload } from '@react-three/drei';
import * as random from 'maath/random/dist/maath-random.esm';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'; 
import UI from "./UI";
import Footer from "./Footer";

gsap.registerPlugin(ScrollTrigger); 

function Stars(props) {
  const ref = useRef()
  const [sphere] = useState(() => random.inSphere(new Float32Array(2500), { radius: 1.5 }))
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 10
    ref.current.rotation.y -= delta / 15
  })
  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
        <PointMaterial transparent color="#666" size={0.0015} sizeAttenuation={true} depthWrite={false} />
      </Points>
    </group>
  )
}
function Rig() {
  useFrame((state) => {
      state.camera.position.lerp({ x: 0, y: -state.pointer.y / 4, z: state.pointer.x / 2 }, 0.1)
      state.camera.lookAt(-1, 0, 0)
  })
}

export function Team() {
    // 페이지 이동 시 스크롤을 상단으로 이동시킴
    const location = useLocation();
      useEffect(() => {
        window.scrollTo(0, 0); 
      }, [location.pathname]);

    //마우스 이벤트 시작
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const itemWidth = 1000; 
    const itemHeight = 1000; 
    const [isCursorOverItem, setIsCursorOverItem] = useState(null);
    const itemImages = {
      item1: 'img/service_2.jpg',
      item2: 'img/menber_fin-02.jpg',
    };
    const isCursorOverElement = (element, cursorX, cursorY) => {
    const rect = element.getBoundingClientRect();
    return (
      cursorX >= rect.left &&
      cursorX <= rect.right &&
      cursorY >= rect.top &&
      cursorY <= rect.bottom
    );
    };
    useEffect(() => {
      const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
    }, []);
    const handleMouseEnter = (e, itemId) => {
      setIsCursorOverItem(itemId);
    };
    const handleMouseLeave = (e) => {
      setIsCursorOverItem(null);
    };
    const cursorStyle = {
      position: 'fixed',
      top: cursorPosition.y - itemHeight / 2 + 'px',
      left: cursorPosition.x - itemWidth / 2 + 'px',
      pointerEvents: 'none',
    };
    //마우스 이벤트 종료

    useEffect(() => {
        //첫 시작시 헤드타이틀
        gsap.from('.sub_title_head', {
            opacity: 0, 
            y: 10, 
            duration: 0.4, 
            delay: 0.3,
            ease: 'power1.easeInOut', 
        });
        // ScrollTrigger 설정
        gsap.utils.toArray('.contents').forEach((content) => {
          gsap.from(content, {
            opacity: 0,
            y: 50,
            duration: 1,
            scrollTrigger: {
              trigger: content,
              start: 'top 80%', 
              end: 'bottom 0%', 
              toggleActions: 'play none none reverse'
            },
          });
        });
      }, []);    
    
    return (
        <>  
        <UI />
        <Canvas className='bg_sub_space' camera={{ position: [0, 0, 1] }}>
            <Stars />
            <Rig from={-Math.PI / 2} to={Math.PI / 2.66} />
            <Preload all />
        </Canvas>
        <div className="sub_title_head">
            <h1>Team</h1>
            <p className='desc_head'>자유, 분위기, 창조성 그리고 각자의 DNA에 대한 존중. <br></br>
그 과정이 서로에 대한 이해로 가득 차 있을 때, 우리는 산을 옮길 수 있어요. 그렇죠? 💪</p>
        </div>
        <div className="sub_bg">
            <div className="sub_container">
                <div className="wrap_grid start">                    
                    
                    <div className="contents type2 team">
                        <div className="wrap_grid start">
                            <div className='title'>Team Spirit</div>
                            <div className='desc'>
                                <div className="desc_word">
                                <h3>Creative Spirit<span className='point_dot'>.</span><br></br>Collaborative Mindset<span className='point_dot'>.</span><br></br>Collective Talent<span className='point_dot'>.</span><br></br>All Together<span className='point_dot'>.</span></h3><br></br>
                                <p className="plain">
디지털생태계는 변화가 빠른것이 특징입니다. 우리는 항상 그 최전선에 있습니다. <br></br>
디지털 미디어 커뮤니케이션 컴퍼니로서의 강점을 살려 광고 비즈니스의 중심에서 새로운 가치를 창출하기 위해 도전합니다. <br></br>
여러분에게 최고의 서포터가 되어줄 우리의 동료를 소개합니다.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="contents type3">
                      
                      <div className="cursor-image" style={cursorStyle}>
                        {isCursorOverItem !== null ? (
                          <img src={itemImages[isCursorOverItem]} alt="Cursor" />
                        ) : null}
                      </div>
                      
                      <div className='type3_item'>
                      {/*<div className='type3_item'  onMouseEnter={(e) => handleMouseEnter(e, 'item1')}   onMouseLeave={handleMouseLeave}>*/}
                        <p><img src='img/menber_fin-01.svg' alt="member" className='item_image'></img></p>
                        <p className='cp_tit'>Team Leader</p>
                        <p className='cp_text'>인군일</p>
                      </div>
                      
                      <div className='type3_item'>
                      {/*<div className='type3_item' onMouseEnter={(e) => handleMouseEnter(e, 'item2')}   onMouseLeave={handleMouseLeave}>*/}
                        <p><img src='img/menber_fin-02.svg' alt="member" className='item_image'></img></p>
                        <p className='cp_tit'>Team Leader / DEV Team</p>
                        <p className='cp_text'>우종선</p>
                      </div>

                      <div className='type3_item'>
                        <p><img src='img/menber_fin-03.svg' alt="member" className='item_image'></img></p>
                        <p className='cp_tit'>Team Leader / Director</p>
                        <p className='cp_text'>조은아</p>
                      </div>

                      <div className='type3_item'>
                        <p><img src='img/menber_fin-04.svg' alt="member" className='item_image'></img></p>
                        <p className='cp_tit'>Video / Camera / Mixed Contents</p>
                        <p className='cp_text'>백승훈</p>
                      </div>
                      
                      <div className='type3_item'>
                        <p><img src='img/menber_fin-05.svg' alt="member" className='item_image'></img></p>
                        <p className='cp_tit'>UI Design / Develop</p>
                        <p className='cp_text'>조장원</p>
                      </div>

                      <div className='type3_item'>
                        <p><img src='img/menber_fin-01.svg' alt="member" className='item_image'></img></p>
                        <p className='cp_tit'>Marketing / Contents Manager</p>
                        <p className='cp_text'>성민선</p>
                      </div>

                      <div className='type3_item'>
                        <p><img src='img/menber_fin-01.svg' alt="member" className='item_image'></img></p>
                        <p className='cp_tit'>Develop</p>
                        <p className='cp_text'>안병욱</p>
                      </div>
                      
                      <div className='type3_item'>
                        <p><img src='img/menber_fin-01.svg' alt="member" className='item_image'></img></p>
                        <p className='cp_tit'>Video / Mixed Contents</p>
                        <p className='cp_text'>이장덕</p>
                      </div>
                      {/*
                      <div className='type3_item'>
                        <p><img src='img/menber_fin-01.svg' className='item_image'></img></p>
                        <p className='cp_tit'>Business Management</p>
                        <p className='cp_text'>김택원</p>
                      </div>

                      
                      <div className='type3_item'>
                        <p><img src='img/menber_fin-01.svg' className='item_image'></img></p>
                        <p className='cp_tit'>Designer</p>
                        <p className='cp_text'>이종태</p>
                      </div>

                      <div className='type3_item'>
                        <p><img src='img/menber_fin-01.svg' className='item_image'></img></p>
                        <p className='cp_tit'>Business Plan</p>
                        <p className='cp_text'>양진영</p>
                      </div>
                      */}

                    </div>
                                   
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}
export default Team;