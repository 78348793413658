//로딩페이지 - 라우팅, 로딩설정
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loading from "./Loading"; 
import Main from './Main';
import Company from './Company';
import Service from './Service';
import Team from './Team';
import Rnd from './Rnd';
import Works from './Works';
import "./styles.css"; //전역 css

export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false); 
    },2000); 
  }, []);

  return (
    <>
    <Router>
      <Routes preload>
          {loading ? (
            <Route path="*" element={<Loading />} />
          ) : (
            <>
              <Route path="/" element={<Main />} />
              <Route path="/works" element={<Works />} />
              <Route path="/service" element={<Service />} />
              <Route path="/team" element={<Team />} />
              <Route path="/rnd" element={<Rnd />} />
              <Route path="/company" element={<Company />} />
            </>
          )}
      </Routes>    
    </Router>
    </>
  )
}
